import React, { FC } from 'react';
import classNames from 'classnames';

import { InjectedClassNameProps, InjectedDataHookProps } from '../../../types';
import styles from './Loader.scss';

type Size = 'small' | 'medium' | 'large';

type InjectedProps = InjectedClassNameProps & InjectedDataHookProps;

export interface LoaderProps extends InjectedProps {
  size?: Size;
}

const Loader: FC<React.PropsWithChildren<LoaderProps>> = ({
  dataHook,
  className,
  size = 'medium',
}) => (
  <i
    data-hook={dataHook}
    className={classNames(className, styles.loader, styles[size])}
  />
);

export default Loader;
