import React, { FC } from 'react';
import { HttpClient } from '@wix/yoshi-flow-editor';
import { useStyles } from '@wix/tpa-settings/react';
import {
  BadgeList as BadgeListComponent,
  Layout,
  Size,
} from '@wix/members-badge-lib';

import stylesParams from '../../../components/ProfileCard/stylesParams';
import {
  BadgeLayout,
  BadgeSettings,
  BadgeSize,
  ProfileLayout,
} from '../../../types';

type BadgeLayoutMap = { [key in BadgeLayout]: Layout };

type BadgeSizeMap = { [key in BadgeSize]: Size };

type BadgeListProps = BadgeSettings & {
  size?: BadgeSize;
  widgetLayout?: ProfileLayout;
};

const badgeLayoutMap: BadgeLayoutMap = {
  [BadgeLayout.IconOnly]: 'icon-only',
  [BadgeLayout.NameOnly]: 'name-only',
  [BadgeLayout.NameAndIcon]: 'name-icon',
};

const badgeSizeMap: BadgeSizeMap = {
  [BadgeSize.Small]: 'small',
  [BadgeSize.Medium]: 'medium',
  [BadgeSize.Large]: 'large',
};

export const BadgeList: FC<BadgeListProps> = ({
  size,
  widgetLayout,
  maxRows,
  ...rest
}) => {
  const styles = useStyles();
  const badgeSize: BadgeSize = styles.get(stylesParams.badgeSize);
  const badgeLayout: BadgeLayout = styles.get(stylesParams.badgeLayout);
  const badgesListMaxRows =
    widgetLayout === ProfileLayout.Card && badgeLayout === BadgeLayout.IconOnly
      ? 3
      : maxRows;

  return (
    <BadgeListComponent
      {...rest}
      httpClient={new HttpClient()}
      borderRadius={styles.get(stylesParams.badgeCornerRadius)}
      font={styles.get(stylesParams.badgeFont).family ?? ''}
      background={styles.get(stylesParams.showBadgeBackground)}
      remainderColor={styles.get(stylesParams.memberNameResponsiveColor).value}
      size={size ? badgeSizeMap[size] : badgeSizeMap[badgeSize]}
      layout={badgeLayoutMap[badgeLayout]}
      maxRows={badgesListMaxRows}
    />
  );
};
