import React, { FC, useState } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { IconTooltip, Popover } from 'wix-ui-tpa/cssVars';
import { More } from '@wix/wix-ui-icons-common/on-stage';

import { DataHook, Position } from '../../../types';
import styles from './MoreButton.scss';
import {
  DEFAULT_HIDE_DELAY,
  DEFAULT_SHOW_DELAY,
} from '../../../constants/tooltip';
import {
  PopoverContent,
  PopoverContentProps,
} from '../MemberMoreButton/PopoverContent';

export interface MoreButtonProps extends PopoverContentProps {
  alignment: Position;
  isRtlLocale: boolean;
  isDisabled?: boolean;
}

const MoreButton: FC<React.PropsWithChildren<MoreButtonProps>> = ({
  alignment,
  isMobile,
  isRtlLocale,
  isDisabled,
  onAdditionalAction,
  additionalActions,
  rolesActions,
  onRoleAction,
}) => {
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const { t } = useTranslation();

  const onOpen = () => setPopoverOpen(true);
  const onClose = () => setPopoverOpen(false);

  const onPopoverClick = () => {
    if (isDisabled) {
      return;
    }

    !isPopoverOpen ? onOpen() : onClose();
  };

  return (
    <Popover
      data-hook={DataHook.MoreActionsPopover}
      placement={alignment === Position.Left ? 'bottom-start' : 'bottom-end'}
      shown={isPopoverOpen}
      onClickOutside={onClose}
      onEscPress={onClose}
      aria-label={t('more-button.more-actions')}
      dynamicWidth={true}
      className={styles.wutPopover}
      onTabOut={onClose}
    >
      <Popover.Element>
        <IconTooltip
          data-hook={DataHook.MoreActionsCTA}
          icon={<More className={styles.moreIcon} />}
          message={t('more-button.tooltip')}
          showTooltip={
            isTooltipOpen && !isPopoverOpen && !isMobile && !isDisabled
          }
          tooltipProps={{
            placement: isRtlLocale ? Position.Right : Position.Left,
            showDelay: DEFAULT_SHOW_DELAY,
            hideDelay: DEFAULT_HIDE_DELAY,
          }}
          onClick={onPopoverClick}
          onFocus={() => setTooltipOpen(true)}
          onBlur={() => setTooltipOpen(false)}
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)}
          disabled={isDisabled}
        />
      </Popover.Element>
      <Popover.Content>
        <PopoverContent
          additionalActions={additionalActions}
          onAdditionalAction={(id) => {
            onAdditionalAction(id);
            onClose();
            setTooltipOpen(false);
          }}
          isMobile={isMobile}
          onRoleAction={(roleId) => {
            onRoleAction(roleId);
            onClose();
            setTooltipOpen(false);
          }}
          rolesActions={rolesActions}
        />
      </Popover.Content>
    </Popover>
  );
};

export default MoreButton;
