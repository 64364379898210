import React, { FC } from 'react';

export const RepositionIcon: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <defs>
        <path
          id="prefix__a"
          d="M2.8 0c.277 0 .5.224.5.5v11.908l1.498-1.301c.2-.174.502-.153.676.046l.01.011.005.007c.171.21.145.517-.059.694L3.151 13.85c-.047.04-.1.07-.156.09-.166.092-.378.079-.533-.049l-.011-.01-2.28-1.983c-.203-.177-.23-.484-.058-.694l.006-.006c.167-.205.47-.235.674-.067l.011.009L2.3 12.44V.5c0-.276.225-.5.5-.5z"
        />
        <path
          id="prefix__b"
          d="M2.8 0c.277 0 .5.224.5.5v11.908l1.498-1.301c.2-.174.502-.153.676.046l.01.011.005.007c.171.21.145.517-.059.694L3.151 13.85c-.047.04-.1.07-.156.09-.166.092-.378.079-.533-.049l-.011-.01-2.28-1.983c-.203-.177-.23-.484-.058-.694l.006-.006c.167-.205.47-.235.674-.067l.011.009L2.3 12.44V.5c0-.276.225-.5.5-.5z"
        />
        <path
          id="prefix__c"
          d="M2.8 0c.277 0 .5.224.5.5v11.908l1.498-1.301c.2-.174.502-.153.676.046l.01.011.005.007c.171.21.145.517-.059.694L3.151 13.85c-.047.04-.1.07-.156.09-.166.092-.378.079-.533-.049l-.011-.01-2.28-1.983c-.203-.177-.23-.484-.058-.694l.006-.006c.167-.205.47-.235.674-.067l.011.009L2.3 12.44V.5c0-.276.225-.5.5-.5z"
        />
        <path
          id="prefix__d"
          d="M2.8 0c.277 0 .5.224.5.5v11.908l1.498-1.301c.2-.174.502-.153.676.046l.01.011.005.007c.171.21.145.517-.059.694L3.151 13.85c-.047.04-.1.07-.156.09-.166.092-.378.079-.533-.049l-.011-.01-2.28-1.983c-.203-.177-.23-.484-.058-.694l.006-.006c.167-.205.47-.235.674-.067l.011.009L2.3 12.44V.5c0-.276.225-.5.5-.5z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(4)">
          <use fill="#FFF" xlinkHref="#prefix__a" />
          <use
            fill="#FFF"
            transform="rotate(180 2.8 7)"
            xlinkHref="#prefix__b"
          />
        </g>
        <g transform="matrix(0 1 1 0 0 4)">
          <use fill="#FFF" xlinkHref="#prefix__c" />
          <use
            fill="#FFF"
            transform="rotate(180 2.8 7)"
            xlinkHref="#prefix__d"
          />
        </g>
      </g>
    </svg>
  );
};
