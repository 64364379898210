import React, { FC, SVGAttributes } from 'react';

const FollowingIcon: FC<React.PropsWithChildren<SVGAttributes<SVGElement>>> = (
  props,
) => (
  <svg
    width="23px"
    height="18px"
    viewBox="0 0 23 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Following icon</title>
    <g
      id="Direct-Message-Final"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Artboard" fillRule="nonzero">
        <g id="Following-icon">
          <path
            d="M3,8.18527872 L10.496095,8.18527872 C12.1529493,8.18527872 13.496095,9.52842447 13.496095,11.1852787 L13.496095,16 C13.496095,16.5522847 13.0483798,17 12.496095,17 L1,17 C0.44771525,17 1.78591498e-13,16.5522847 1.78523862e-13,16 L1.78523862e-13,11.1852787 C1.78320956e-13,9.52842447 1.34314575,8.18527872 3,8.18527872 Z"
            id="Rectangle-2-Copy-2"
          ></path>
          <ellipse
            id="Oval-2-Copy-3"
            cx="7.09859155"
            cy="3.30552048"
            rx="3.68075117"
            ry="3.30552048"
          ></ellipse>
          <path
            d="M19.3909524,2.75486582 L19.2897372,2.76189795 C18.9269311,2.81286142 18.6607437,3.13413327 18.6607437,3.50591904 L18.66,7.985 L15.8917811,7.98514116 C15.4987329,7.98514116 15.2076141,8.31584797 15.2076141,8.69435217 L15.2076141,9.32198521 C15.2076141,9.70048941 15.4987329,10.0311962 15.8917811,10.0311962 L20.12762,10.0311962 C20.5206682,10.0311962 20.8117869,9.70048941 20.8117869,9.32198521 L20.8117869,3.50591904 C20.8117869,3.10033456 20.4950019,2.75486582 20.0815783,2.75486582 L19.3909524,2.75486582 Z"
            id="Path"
            transform="translate(18.009701, 6.393031) rotate(-315.000000) translate(-18.009701, -6.393031) "
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default FollowingIcon;
