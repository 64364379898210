import React, { FC } from 'react';
import classNames from 'classnames';

import Loader, { LoaderProps } from '../Loader';
import styles from './LoaderOverlay.scss';

interface LoaderOverlayProps extends LoaderProps {
  backdropClassName?: string;
}

const LoaderOverlay: FC<React.PropsWithChildren<LoaderOverlayProps>> = (
  props,
) => (
  <div className={classNames(styles.container, props.backdropClassName)}>
    <Loader {...props} />
  </div>
);

export default LoaderOverlay;
