import React, { FC } from 'react';
import classNames from 'classnames';
import { IconTooltip } from 'wix-ui-tpa/cssVars';

import { InjectedTranslateProps, InjectedClassNameProps } from '../../../types';
import styles from './Icon.scss';

interface IconProps extends InjectedClassNameProps, InjectedTranslateProps {
  className?: string;
  iconClassName?: string;
  icon: any;
  tooltip?: string;
}

const Icon: FC<React.PropsWithChildren<IconProps>> = ({
  className,
  iconClassName,
  icon,
  tooltip,
  t,
}) => {
  const [showTooltip, setShowTooltip] = React.useState(false);

  return tooltip ? (
    <IconTooltip
      message={t(tooltip)}
      icon={icon}
      showTooltip={showTooltip}
      onClick={() => setShowTooltip(!showTooltip)}
      onClickOutside={() => setShowTooltip(!showTooltip)}
      contentClassName={classNames(iconClassName, styles.svgContainer)}
    />
  ) : (
    <div className={classNames(styles.container, className)}>
      <span className={classNames(iconClassName, styles.svgContainer)}>
        {icon}
      </span>
    </div>
  );
};

export default Icon;
