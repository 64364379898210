import React, { FC } from 'react';
import { RoleAction } from '@wix/members-domain-ts';

import { AdditionalAction, DataHook, Position } from '../../../types';
import MoreButton from '../MoreButton';
import { OnAdditionalAction, OnRoleAction } from './PopoverContent';

interface MemberMoreButtonProps {
  containerClass: string;
  additionalActions: AdditionalAction[];
  rolesActions: RoleAction[];
  alignment: Position;
  isMobile: boolean;
  isRtlLocale: boolean;
  isDisabled?: boolean;
  onAdditionalAction: OnAdditionalAction;
  onRoleAction: OnRoleAction;
}

const MemberMoreButton: FC<React.PropsWithChildren<MemberMoreButtonProps>> = ({
  containerClass,
  additionalActions,
  rolesActions,
  alignment,
  isMobile,
  isRtlLocale,
  isDisabled,
  onAdditionalAction,
  onRoleAction,
}) => {
  if (!rolesActions.length && !additionalActions.length) {
    return null;
  }

  return (
    <div data-hook={DataHook.MoreActionsWrapper} className={containerClass}>
      <MoreButton
        alignment={alignment}
        isRtlLocale={isRtlLocale}
        isMobile={isMobile}
        isDisabled={isDisabled}
        additionalActions={additionalActions}
        rolesActions={rolesActions}
        onAdditionalAction={onAdditionalAction}
        onRoleAction={onRoleAction}
      />
    </div>
  );
};

export default MemberMoreButton;
