import React, { useEffect, useRef } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { Close } from '@wix/wix-ui-icons-common/on-stage';
import { IconButton } from 'wix-ui-tpa/cssVars';
import classNames from 'classnames';

import {
  DataHook,
  InjectedComputedProps,
  InjectedHandlers,
  InjectedProfilePage,
  ProfileAlignment,
} from '../../../../../../types';
import { absorbArguments } from '../../../../../../services/event-handler';
import { RenderButtonProps } from '../types';

import styles from '../DesktopResponsiveWidget.scss';

type CoverRepositionButtonsProps = {
  profilePage: InjectedProfilePage;
  handlers: InjectedHandlers;
  computed: InjectedComputedProps;
  isMovingCover: boolean;
  renderButton: (props: RenderButtonProps) => React.JSX.Element;
};

function CoverRepositionButtons({
  profilePage,
  handlers,
  isMovingCover,
  computed,
  renderButton,
}: CoverRepositionButtonsProps) {
  const { t } = useTranslation();
  const { isRTL } = useEnvironment();
  const cardCoverCancelRef = useRef<HTMLButtonElement>(null);

  const { isCoverRepositionMode } = profilePage;
  const { leaveCoverRepositionMode, saveCoverReposition } = handlers;

  const handleCoverRepositionCancel = absorbArguments(leaveCoverRepositionMode);

  const handleCoverRepositionSave = absorbArguments(saveCoverReposition);

  const buttonsBoxClasses = {
    [styles.buttonsBox]: true,
    [styles.buttonsBoxAlignLeftLTR]:
      computed.profileAlignment === ProfileAlignment.Right && !isRTL,
  };

  useEffect(() => {
    cardCoverCancelRef.current?.focus();
  }, [isCoverRepositionMode, isMovingCover]);

  return (
    <>
      {isCoverRepositionMode && !isMovingCover && (
        <div className={styles.repositionModeButtons}>
          <div className={classNames(buttonsBoxClasses)}>
            <IconButton
              icon={<Close />}
              onClick={handleCoverRepositionCancel}
              data-hook={DataHook.CardCoverCancel}
              className={styles.cancelButton}
              innerRef={cardCoverCancelRef}
            />
            {renderButton({
              className: styles.truncateText,
              dataHook: DataHook.CardCoverSave,
              title: t('profile-widget.done'),
              isSecondary: true,
              onClick: handleCoverRepositionSave,
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default CoverRepositionButtons;
